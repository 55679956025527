export default function TitlePage({ title, children }) {
  return (
    <div className="flex items-center w-full mb-2">
      <div className="flex justify-between items-center gap-4 w-full">
        <div className="font-semibold text-4xl __text">{title}</div>
        {children}
      </div>
    </div>
  )
}
