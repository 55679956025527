import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import localeDefault from "./localeDefault"
import { apiAms } from "api"
import { setI18nextLng } from "lib/cookie"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...localeDefault
        }
      }
    }
  })

export default i18n

const setI18Language = (key) => {
  setI18nextLng(key)
  i18n.changeLanguage(key)
}

export async function changeLanguage(key) {
  // check local
  // api check version
  const { data } = await apiAms.getCurrentLatestVersion()
  const versionData = data
  let language = JSON.parse(localStorage.getItem("i18nLanguage"))
  if (
    versionData &&
    versionData?.current_latest_version &&
    language &&
    language[key] &&
    language[key].latestVersion === versionData.current_latest_version
  ) {
    i18n.addResources(key, "translation", language[key].data)
    setI18Language(key)
  } else {
    return new Promise(async (resolve, reject) => {
      const currentLocales = JSON.parse(localStorage.getItem("preLocale") || "{}")

      // if language resource exists
      if (currentLocales[key]) {
        i18n.addResources(key, "translation", currentLocales[key])
        setI18Language(key)
      }

      // call api to get lastest language version whether language exist or not
      try {
        const { data } = await apiAms.getTranslate({
          language_code: key
        })
        let newLanguage = {}
        for (let i = 0; i < data.length; i++) {
          let item = data[i].element_id?.toString()?.replace(/:/g, "_")
          newLanguage[item] = data[i].translation_content
        }

        i18n.addResources(key, "translation", newLanguage)
        localStorage.setItem("preLocale", JSON.stringify({ ...currentLocales, [key]: newLanguage }))

        // save data to localStorage
        if (versionData && versionData?.current_latest_version) {
          if (language) {
            localStorage.setItem(
              "i18nLanguage",
              JSON.stringify({
                ...language,
                [key]: { latestVersion: versionData.current_latest_version, data: newLanguage }
              })
            )
          } else {
            localStorage.setItem(
              "i18nLanguage",
              JSON.stringify({
                [key]: { latestVersion: versionData.current_latest_version, data: newLanguage }
              })
            )
          }
        }

        setI18Language(key)
      } catch (error) {
      } finally {
        resolve()
      }
    })
  }
}
